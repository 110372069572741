import { MenuItem } from './menu.model';

export const MENU_ITEM: MenuItem[] = [
    { id: 1, label: 'MENUITEMS.MENU.TEXT', isTitle: true },
]

export const MENU_CLIENT: MenuItem[] = [
    { id: 130, label: 'Clientes', isTitle: true },
    { id: 131, label: 'Dashboard', icon: 'bx-home-circle', link: '/clientes', },
    { id: 132, label: 'Nuevo Pagaré', icon: 'bx-edit', link: '/clientes/nuevo-pagare', },
    { id: 133, label: 'Mis Pagarés', icon: 'bx-briefcase-alt-2', link: '/clientes/mis-pagares', },
    { id: 134, label: 'Nuevo Contacto', icon: 'bx-edit', link: '/clientes/nuevo-contacto', },
    { id: 135, label: 'Mis Contactos', icon: 'bxs-user-detail', link: '/clientes/mis-contactos', },
    { id: 137, label: 'Calculadora', icon: 'bx-calculator', link: '/clientes/calculadora', },
    // { id: 136, label: 'Cambiar Contraseña', icon: 'bxs-lock', link: '/clientes/actualizar-mi-clave', },
];

export const MENU_ADMIN: MenuItem[] = [
    { id: 140, label: 'Administración', isTitle: true },
    { id: 141, label: 'Dashboard', icon: 'bx-home-circle', link: '/admsystem', },
    { id: 141, label: 'Clientes', icon: 'bxs-user-detail', link: '/admsystem/clientes', },
    { id: 142, label: 'Pagarés', icon: 'bx-briefcase-alt-2', link: '/admsystem/pagares', },
    { id: 143, label: 'Usuarios', icon: 'bxs-user-detail', link: '/admsystem/usuarios', },
];