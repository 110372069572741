import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../core/helpers/http-error.handle';

@Injectable()
export class UserService {

    public currentUser: string;
    public token: string;
    handler: HttpErrorHandler = new HttpErrorHandler();
    
    constructor(
        public router: Router,
        public http: HttpClient,
    ) { }

    login(entity): Observable<any> {
        let params = JSON.stringify(entity);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(environment.baseUrl + '/auth', params, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("login")));
    }

    register(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        let url = environment.baseUrl + '/auth/register';
        return this.http.post(url, JSON.stringify(entity), { headers: headers });
    }
    
    sendTokenResetPassword(email: string): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        let url = environment.baseUrl + '/auth/sendTokenResetPassword';
        return this.http.post(url, { email: email }, { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("login")));
    }

    validToken(token: string, upd: boolean): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        let url = environment.baseUrl + '/auth/validToken';
        return this.http.post(url, { token, upd }, { headers: headers });
    }

    resetPwd(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/auth/resetPwd`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("auth/resetPwd")));
    }
    
    saveStorage(response) {
        localStorage.setItem('currentUser', JSON.stringify(response.data));
        localStorage.setItem('token', JSON.stringify(response.access_token));
    }

    saveCurrentUser(data) {
        localStorage.setItem('currentUser', JSON.stringify(data));
    }

    getCurrentUser() {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return currentUser != "undefined" ? currentUser : null;
    }

    getToken() {
        let token = localStorage.getItem('token');
        return token != "undefined" ? token : null;
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        localStorage.clear();
    }
    
    update(entity): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(`${environment.baseUrl}/user/update`, JSON.stringify(entity), { headers: headers })
        .pipe(catchError(this.handler.handleError<[]>("user/update")));
    }
}
