import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
// import { AuthenticationService } from '../services/auth.service';
// import { AuthfakeauthenticationService } from '../services/authfake.service';
// import { environment } from '../../../environments/environment';
import { throttleTime } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private throttleLogout = new Subject();

    constructor(
        // private authenticationService: AuthenticationService, 
        // private authfackservice: AuthfakeauthenticationService
    ){
        this.throttleLogout.pipe(throttleTime(5000)).subscribe(url => {
            this.logout();
        });
    }
    
    logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        localStorage.clear();
    }

    getToken() {
        let token = localStorage.getItem('token');
        if (token != "undefined") {
            return token;
        }
        return null;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if (environment.defaultauth === 'firebase') {
        //     const currentUser = this.authenticationService.currentUser();
        //     if (currentUser && currentUser.token) {
        //         request = request.clone({ setHeaders: { Authorization: `Bearer ${currentUser.token}` } });
        //     }
        // } else {
            // add authorization header with jwt token if available
            const token = this.getToken();
            if (token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }
        // }
        return next.handle(request);
    }
}
