import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';

const routes: Routes = [
  
  { path: '', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },

  { path: 'clientes', component: LayoutComponent, loadChildren: () => import('./clientes/clientes.module').then(m => m.ClientesModule), canActivate: [AuthGuard] },

  { path: 'admsystem', component: LayoutComponent, loadChildren: () => import('./admsystem/admsystem.module').then(m => m.AdmSystemModule), canActivate: [AuthGuard] },
  
  // { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
