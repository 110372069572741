export const environment = {
  production: true,
  baseUrl: 'https://neufeld.tedalab.com/api',
    // baseUrl: 'http://localhost:3000',
  // baseUrl: 'http://192.168.0.29:3000',
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};
